import React, { FunctionComponent } from 'react'
import { MarkdownRemarkConnection, Site } from '@/graphql-types'
import { graphql } from 'gatsby'
import { useSpring, animated } from 'react-spring'
import Layout from '../components/layout/layout'
import { PostItem } from '../components/post-item'

const Styles = require('../styles/templates/series-list.module.styl')

type Props = {
  data: {
    allMarkdownRemark: MarkdownRemarkConnection
    site: Site
  }
  location: Location
}

const SeriesListTemplate: FunctionComponent<Props> = props => {
  const seriesList = props.data.allMarkdownRemark.edges.slice(1)
  const readme = props.data.allMarkdownRemark.edges[0].node.frontmatter
  const spring = useSpring({
    opacity: 1,
    transform: 'translate3d(0,0,0)',
    from: { opacity: 0, transform: 'translate3d(0, -50px, 0)', width: '100%' },
  })
  return (
    <Layout headerCls={Styles['header']}>
      <animated.div style={spring}>
        <div className={Styles['main']}>
          <div className={Styles['content']}>
            <p className={Styles['title']}> {readme?.title} </p>
            <div className={Styles['line']} />
            <p className={Styles['description']}> {readme?.description} </p>
            <p className={Styles['other']}>
              <span className={Styles['num']}> {seriesList.length} </span> 篇内容
            </p>
          </div>
          <div className={Styles['list']}>
            {seriesList.map(x => {
              return <PostItem path={`post-${new Date(x.node.frontmatter?.date).getTime()}`} key={x.node.id} node={x.node} />
            })}
          </div>
        </div>
      </animated.div>
    </Layout>
  )
}

export default SeriesListTemplate

export const SeriesQuery = graphql`
  query SeriesQueryBySlug($category: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    allMarkdownRemark(sort: { fields: frontmatter___date, order: ASC }, filter: { fileAbsolutePath: { regex: $category } }) {
      edges {
        node {
          id
          frontmatter {
            date
            description
            tags
            title
          }
          fields {
            slug
          }
          fileAbsolutePath
          wordCount {
            words
          }
          timeToRead
        }
      }
    }
  }
`
